import React, { useState } from "react";

const NotificationForm = ({ socket }) => {
  const [data, setData] = useState({
    title: "",
    body: "",
    image: "",
    error: null,
    loading: false,
  });

  const { title, body, image, error, loading } = data;

  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setData({ ...data, error: null, loading: true });

    try {
      await socket.emit("notification", { title, body, image });
      window.location = "/notifications";
    } catch (error) {
      setData({ ...data, error: error, loading: false });
    }
    setData({
      title: "",
      body: "",
      image: "",
      error: null,
      loading: false,
    });
  };

  return (
    <section>
      <h3>Create New Notification</h3>
      <form className="form" onSubmit={handleSubmit}>
        <div className="input_container">
          <label htmlFor="title">Title</label>
          <input
            type="text"
            name="title"
            value={title}
            onChange={handleChange}
            required
          />
        </div>
        <div className="input_container">
          <label htmlFor="body">Body</label>
          <textarea
            name="body"
            rows={5}
            value={body}
            onChange={handleChange}
            required
          />
        </div>
        <div className="input_container">
          <label htmlFor="image">Image</label>
          <input
            type="text"
            name="image"
            value={image}
            onChange={handleChange}
          />
        </div>
        {error ? <p className="error">{error}</p> : null}
        <div className="btn_container">
          <button className="btn" disabled={loading}>
            {loading ? "Creating..." : "New"}
          </button>
        </div>
      </form>
    </section>
  );
};

export default NotificationForm;
