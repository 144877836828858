import React, { useRef, useEffect } from 'react'
import Moment from 'react-moment'
import { host } from '../services/api'

const Message = ({ msg, sender }) => {
  const scrollRef = useRef()

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [msg])

  return (
    <div
      className={`message_wrapper ${msg.senderId === sender ? 'own' : ''}`}
      ref={scrollRef}
    >
      <p className={msg.senderId === sender ? 'me' : 'friend'}>
        {msg.media ? (
          <img
            crossOrigin="anonymous"
            src={`${host}/api/messages/view/${msg.media}`}
            alt={msg.message}
          />
        ) : null}
        {msg.message}
        <br />
        <small>
          <Moment fromNow>{msg.createdAt}</Moment>
        </small>
      </p>
    </div>
  )
}

export default Message
