import axios from "axios";

export const host = "http://150.95.31.137:8001";
export const apiHost="http://52.47.68.101/marthin_be/public/api/v1"
//export const host = 'http://localhost:3100'

export const call = async (method, path, data) => {
  const response = await axios[method](`${host}/api${path}`, data);
  return response.data;
};



export const apiCall = async (method, path, data) => {
  const response = await axios[method](`${apiHost}${path}`, data);
  return response.data;
};
