import React, { useState, useEffect } from "react";
import { call } from "../services/api";
import Moment from "react-moment";
import styles from "./Notifications.module.css";

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    async function fetchNoti() {
      try {
        const result = await call("get", "/notifications");

        setNotifications(result.data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchNoti();
  }, []);

  return (
    <table className={styles.styled_table}>
      <thead>
        <tr>
          <th>No</th>
          <th>Title</th>
          <th>Body</th>
          <th>Image</th>
          <th>CreatedAt</th>
        </tr>
      </thead>
      <tbody>
        {notifications.map((noti, index) => (
          <tr key={noti.id}>
            <td>{index + 1}</td>
            <td>{noti.title}</td>
            <td>{noti.body}</td>
            <td>{noti.image}</td>
            <td>
              <Moment fromNow>{noti.created_at}</Moment>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Notifications;
