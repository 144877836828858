import React from 'react'

const User = ({ user, selectUser, chat }) => {
  return (
    <>
      <div
        className={`user_wrapper ${
          chat && chat.name === user.name && 'selected_user'
        }`}
        onClick={() => selectUser(user)}
      >
        <div className="user_info">
          <div className="user_detail">
            <h4>{user.name}</h4>
          </div>
        </div>
      </div>
    </>
  )
}

export default User
