import React, { useEffect, useRef, useState } from "react";
import styles from "./../Notifications.module.css";
import { apiCall } from "../../services/api";
import { useParams } from "react-router-dom";
import { NotificationManager } from "react-notifications";

function PlusOneCommentsPage() {
  const [data, setData] = useState([]);
  const { id } = useParams();
  async function loadData() {
    const result = await apiCall("get", `/plus-ones/comments/${id}`);
    setData(result.data);
  }
  useEffect(() => {
    loadData();
  }, []);

  const handleDelete = async (id) => {
    try {
      await apiCall("delete", `/plus-ones/comments-admin-delete/${id}`);
      NotificationManager.success("Comment has been deleted successfully");
      await loadData();
    } catch (error) {
      NotificationManager.error("Comment cannot be deleted");
    }

    await loadData();
  };

  const handleReply = async (data) => {
    try {
      await apiCall("post", "/plus-ones/comments-admin-reply", data);
      NotificationManager.success("Comment has been updated successfully");
      await loadData();
    } catch (error) {
      NotificationManager.error("Comment cannot be updated");
    }
  };

  return (
    <table className={styles.styled_table}>
      <thead>
        <tr>
          <th>No</th>
          <th>UserName</th>
          <th>Comment</th>
          <th>Time</th>
          <th>Reply</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((row, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{row.user?.name}</td>

            <td>{row.body}</td>
            <td>{row.created_at}</td>
            <td>
              <Reply
                reply={row.reply ? row.reply : ""}
                onReply={handleReply}
                commentId={row.id}
              />
            </td>
            <td>
              <button onClick={() => handleDelete(row.id)}>Delete</button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function Reply({ commentId, reply, onReply }) {
  const [replyState, setReplyState] = useState(reply);
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (replyState) {
      onReply({ id: commentId, reply: replyState });
    }
  };
  return (
    <form style={textBoxContainerStyle} onSubmit={handleSubmit}>
      <textarea
        rows="4"
        cols="40"
        value={replyState}
        onChange={(e) => setReplyState(e.target.value)}
      ></textarea>
      <button
        type="submit"
        style={{
          backgroundColor: "lightgreen",
          maxWidth: "50%",
        }}
      >
        Save
      </button>
    </form>
  );
}

export default PlusOneCommentsPage;

const textBoxContainerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "end",
  gap: "10px",
};
