import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import './Navbar.css'
const Navbar = () => {
   const location = useLocation();
  return (
    <nav>
      <h3>
        <Link to="/" className={location.pathname === "/" ? "active" : "link"}>
          Messenger
        </Link>
      </h3>
      <div>
        <Link
          to="/plus-ones"
          className={location.pathname.startsWith("/plus-ones") ? "active" : "link"}
        >
          +1
        </Link>
        <Link to="/3-min" className={location.pathname.startsWith("/3-min") ? "active" : "link"}>
          3 min
        </Link>
        <Link
          to="/notifications"
          className={location.pathname === "/notifications" ? "active" : "link"}
        >
          Notifications
        </Link>
        <Link
          to="/create-notifications"
          className={location.pathname === "/create-notifications" ? "active" : "link"}
        >
          Create Noti
        </Link>
      </div>
    </nav>
  );
}

export default Navbar
