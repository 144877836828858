import React, { useEffect, useState } from "react";
import User from "../components/User";
import MessageForm from "../components/MessageForm";
import Message from "../components/Message";
import { adminID } from "../variables";
import { call } from "../services/api";
import SearchForm from "../components/SearchForm";

const Home = ({ socket }) => {
  const [users, setUsers] = useState([]);
  const [chat, setChat] = useState(null);
  const [text, setText] = useState("");
  const [search, setSearch] = useState("");
  const [img, setImg] = useState(null);
  const [msgs, setMsgs] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const result = await call("get", "/users");

        setUsers(result.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUsers();

    return () => fetchUsers();
  }, []);

  useEffect(() => {
    const handler = (data) => {
      if (parseInt(data.senderId) === parseInt(chat?.org_id))
        setMsgs((msgs) => [...msgs, data]);
      const newUser = { userId: data.user.id, user: data.user };
      if (data.user) {
        const filter = users.filter(
          (user) => user.userId !== parseInt(data.user.id)
        );

        setUsers([newUser, ...filter]);
      } else {
        setUsers((data) => [newUser, ...data]);
      }
    };

    socket.on("message-receive", handler);

    return () => socket.off("message-receive", handler);
  }, [socket, chat, users]);

  const selectUser = async (user) => {
    setChat(user);
    const chatId = adminID.toString() + user.org_id.toString();

    try {
      const result = await call("get", `/messages/admin/${chatId}`);

      setMsgs(result.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const chatId = adminID.toString() + chat?.org_id.toString();
    let media = "";
    if (img) {
      try {
        const formData = new FormData();
        formData.append("file", img);
        const result = await call("post", `/messages/fileupload`, formData);
        media = result.data;
      } catch (error) {
        console.log(error);
      }
    }

    if (text !== "" || img) {
      const message = {
        chatId: chatId,
        senderId: adminID,
        receiverId: chat.org_id,
        message: text,
        media: media,
        createdAt: new Date(Date.now()),
      };
      console.log(message);
      await socket.emit("message", message);
      setMsgs((msgs) => [...msgs, message]);
      setText("");
      setImg(null);

      const filter = users.filter((user) => user.userId !== parseInt(chat.id));

      const findUser = users.find((user) => user.userId === chat.id);

      setUsers([findUser, ...filter]);
    }
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    try {
      const result = await call("get", `/users?search=${search}`);

      setUsers(result.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="home_container">
      <SearchForm
        handleSubmit={handleSearch}
        search={search}
        setSearch={setSearch}
      />
      <div className="users_container">
        {users.map((user) => (
          <User
            key={user.user.id}
            user={user.user}
            selectUser={selectUser}
            chat={chat}
          />
        ))}
      </div>
      <div className="messages_container">
        {chat ? (
          <>
            <div className="messages_user">
              <h3>{chat.name}</h3>
            </div>
            <div className="messages">
              {msgs.length
                ? msgs.map((msg, i) => (
                    <Message key={i} msg={msg} sender={adminID} />
                  ))
                : null}
            </div>
            <MessageForm
              handleSubmit={handleSubmit}
              text={text}
              setText={setText}
              setImg={setImg}
            />
          </>
        ) : (
          <h3 className="no_conv">Select a user to start conversation</h3>
        )}
      </div>
    </div>
  );
};

export default Home;
