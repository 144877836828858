import React, { useEffect, useState } from 'react'
import styles from "./../Notifications.module.css";
import { apiCall } from '../../services/api';
import { useNavigate } from "react-router-dom";

function ThreeMinPage() {
   const [data, setData] = useState([]);
   const navigate = useNavigate();

   useEffect(() => {
     async function loadData() {
       const result = await apiCall("get", "/three-minutes");
       setData(result.data);
     }
     loadData();
   }, []);

   const handleView = (id) => {
     navigate(`/3-min/comments/${id}`);
   };
  
    return (
      <table className={styles.styled_table}>
        <thead>
          <tr>
            <th>No</th>
            <th>Title</th>
            <th>Image</th>
            <th>Comments</th>
            <th>View Comment</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((row, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{row.title}</td>
              <td>
                <img src={row.thumbnail} />
              </td>
              <td>{row.comments}</td>
              <td>
                <button onClick={() => handleView(row.id)}>view</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
}

export default ThreeMinPage