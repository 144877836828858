import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import io from "socket.io-client";
import "./App.css";
import { adminID } from "./variables";
import { host } from "./services/api";
import Navbar from "./components/Navbar";
import Notifications from "./pages/Notifications";
import NotificationForm from "./pages/NotificationForm";
import PlusOnePage from "./pages/PlusOne";
import ThreeMinPage from "./pages/ThreeMin";
import PlusOneCommentsPage from "./pages/PlusOne/Comment";
import ThreeMinutesCommentsPage from "./pages/ThreeMin/Comment";
import { NotificationContainer } from "react-notifications";

const socket = io.connect(host);

const App = () => {
  const userConnected = async () => {
    await socket.emit("user_connected", adminID);
  };

  useEffect(() => {
    userConnected();

    return () => userConnected();
  }, []);

  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="notifications" element={<Notifications />} />
        <Route
          path="create-notifications"
          element={<NotificationForm socket={socket} />}
        />
        <Route path="plus-ones" element={<PlusOnePage />} />
        <Route
          path="plus-ones/comments/:id"
          element={<PlusOneCommentsPage />}
        />
        <Route path="3-min" element={<ThreeMinPage />} />
        <Route
          path="3-min/comments/:id"
          element={<ThreeMinutesCommentsPage />}
        />
        <Route path="/" element={<Home socket={socket} />} />
      </Routes>
      <NotificationContainer />
    </BrowserRouter>
  );
};

export default App;
