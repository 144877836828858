import React from 'react'

const SearchForm = ({ handleSubmit, search, setSearch }) => {
  return (
    <form className="search_form" onSubmit={handleSubmit}>
      <div>
        <input
          type="text"
          placeholder="Enter name"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div>
        <button className="btn">Search</button>
      </div>
    </form>
  )
}

export default SearchForm
